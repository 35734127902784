import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Routes
import DepositRoutes from "./Routes";

// Redux
import { setDepositData, setDataBankCustomerAll } from "./../../../../../@core/redux/actions/deposit/index";
import { setStatusSpinner } from "./../../../../../@core/redux/actions/app/spinner";

// auth
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// Hooks
// import useParameterUrl from "./../../../../../@utility/hooks/useParameterUrl";

// Services
import { loadBankDepositAll } from "./../../../../../@core/services/bankService";
import { loadDepositFirst } from "./../../../../../@core/services/depositService";

// @utility
import { setStorage } from "./../../../../../@utility/app/storage";
import alertText from "./../../../../../@utility/app/alertText";

// Components
import CloseButton from "./../../components/CloseButton";
import SnackbarSet from "./../../components/SnackbarSet";

//  CSS
import modalStyles from "./../../assets/css/Modal.module.css";

const Layouts = ({ menu_status = 1 }) => {
    document.title = process.env.REACT_APP_OG_SITE_NAME+ " - เติมเงิน";
    const history = useHistory();
    const dispatch = useDispatch();
    const childRef = useRef();
    const depositItems = useSelector(state => state.reduxDeposit.result);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);
    const [loadBank, setLoadBank] = useState(false);

    const loadBankDeposit = () => {
        loadBankDepositAll().then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDataBankCustomerAll(data.result));
                setLoadBank(true);
            }
            else {
                setLoadBank(true);
            }
        });
    };

    const reloadData = () => {
        if (isUserLoggedIn()) {
            let snackbarJson = {};
            if (depositItems === false) {
                dispatch(setStatusSpinner({status: true, textProcess: null}));
            }

            loadDepositFirst().then((data) => {
                const statusCode = data.code;
                dispatch(setStatusSpinner({status: false, textProcess: null}));
                
                if (statusCode === 200 || statusCode === 0) {
                    // setDepositItems(data.result);
                    dispatch(setDepositData(data.result));
                    if (data.result.mode === "pay") {
                        setStorage("processDeposit", 1);
                    }
                    else if(data.result.mode === "deposit") {
                        loadBankDeposit();
                    }
                }
                else {
                    dispatch(setDepositData(null));

                    if (statusCode === 2 || statusCode === 4) {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);

                        setTimeout(function () {
                            window.location.href = `/`;
                        }, 5000);
                    }
                    else {
                        snackbarJson = alertText(statusCode);
                        childRef.current.snackbar(snackbarJson);
                    }
                }
            });
        }
        else {
            // setLoadRoutes(true);
            dispatch(setStatusSpinner({status: false, textProcess: null}));
        }
    };

    useEffect(() => {
        if (menu_status !== 1 && menu_status !== undefined) {
            history.push("/");
        }
    }, []);

    
    useEffect(() => {
        reloadData();
    }, [dispatch, isUserLoggedIn()]);

    return <>
        <div className={modalStyles.contentPosition}>
            <div className={modalStyles.boxBodyFull}>
                <div className={modalStyles.boxFull}>
                    <div className={modalStyles.closeButtonFull}>
                        <CloseButton backPage="/"></CloseButton>
                    </div>
                    <div className={modalStyles.boxContentWidthFull}>
                        {
                            (depositItems !== false || isUserLoggedIn() === false) && (
                                <DepositRoutes isUserLoggedIn={isUserLoggedIn()} depositItems={depositItems} reduxAppCMS={reduxAppCMS} loadBankDeposit={loadBankDeposit} loadBank={loadBank}></DepositRoutes>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className={modalStyles.fixedBackdrop}></div>
        <SnackbarSet ref={childRef} />
    </>;
}

export default Layouts;