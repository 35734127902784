import { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// @Utility
import { getStorage, removeStorage } from "./../../../../../../@utility/app/storage";
import alertText from "./../../../../../../@utility/app/alertText";
import validateInput from "./../../../../../../@utility/app/validateInput";

// Redux
import { setHomeData } from "./../../../../../../@core/redux/actions/home/index";
import { setStatusSpinner } from "./../../../../../../@core/redux/actions/app/spinner";
import { setDataPopupContact } from "./../../../../../../@core/redux/actions/app/popup";

// Services
import { registerShort as registerApp } from "./../../../../../../@core/auth/jwt/jwtService";
import { loadHomeData } from "./../../../../../../@core/services/homeService";

//  CSS
import formStyles from "./../../../assets/css/Input.module.css";
import buttonStyles from "./../../../assets/css/Button.module.css";

// Icon
import PhoneIcon from '@material-ui/icons/Phone';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PersonIcon from '@material-ui/icons/Person';

// Component
import SnackbarSet from "./../../SnackbarSet";

export default function Register01App(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const childRef = useRef();
    const dispatch = useDispatch();
    const [selectBank, setSelectBank] = useState(null);
    const [showAccName, setShowAccName] = useState(false);

    // const snackbar = (da) => {
    //     childRef.current.snackbar(da);
    // }

    const onSubmit = (data) => {
        if(selectBank !== null) {
            if(data.phone !== "" && data.bank_account) {
                let dataBankAccountName = "";
                let statusProcess = false;
                if(data?.bank_account_name !== undefined) {
                    if(data?.bank_account_name !== "") {
                        statusProcess = true;
                        dataBankAccountName = data?.bank_account_name;
                    }
                    else {
                        statusProcess = false;
                        dispatch(props.setDataAlert({
                            type: "warning",
                            title: "แจ้งเตือน",
                            text: "กรุณากรอกข้อมูลชื่อบัญชีธนาคาร !",
                            action: true,
                            redirect: "",
                            show: true,
                            buttonText: "ตกลง"
                        }));
                    }
                }
                else {
                    statusProcess = true;
                    dataBankAccountName = "";
                }

                if(statusProcess) {
                    dispatch(setStatusSpinner({status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..."}));
                    let myJson = {
                        "username": data.phone,
                        "password": "",
                        "affiliate": getStorage("affiliate") === null ? "" : getStorage("affiliate"),
                        "refer": getStorage("refer") === null ? "" : getStorage("refer"),
                        "know_web": "",
                        "allusion": getStorage("allusion"),
                        "bank_account": data.bank_account,
                        "bank_name": dataBankAccountName,
                        "bank_swift": selectBank,
                        "fbc": getStorage("fbclid") === null ? "" : getStorage("fbclid"),
                        "keitaro_id": getStorage("clickid") === null ? "" : getStorage("clickid"),
                    }

                    if (getStorage("market") !== null && getStorage("market") !== "Null" && getStorage("market") !== "null" && getStorage("market") !== "") {
                        myJson.refer = getStorage("market")
                    }      

                    registerApp(myJson).then((data) => {
                        dispatch(setStatusSpinner({status: false, textProcess: null}));
                        const statusCode = data.code;
                        if (statusCode === 200 || statusCode === 0) {
                            const password = data.password;
                            reloadHomeData();
                            dispatch(props.setDataAlert({
                                type: "success",
                                title: password === null ? "แจ้งเตือน" : "สมัครสมาชิกสำเร็จ",
                                text: password === null ? "สมัครสมาชิกสำเร็จ !" : `รหัสผ่านของคุณคือ : ${password}` ,
                                action: true,
                                redirect: "",
                                show: true,
                                buttonText: "ตกลง"
                            }));
                            
                            dispatch(setDataPopupContact(true));
                            props.onShow(false)
                            // removeStorage("refer");
                            // removeStorage("affiliate");
                            removeStorage("allusion");
                        }
                        else {
                            let dataAlt = alertText(statusCode);
                            dispatch(props.setDataAlert({
                                type: dataAlt.statusAlert,
                                title: dataAlt.titleAlert,
                                text: dataAlt.textAlert,
                                action: true,
                                redirect: "",
                                show: true,
                                buttonText: "ตกลง"
                            }));

                            if(statusCode === 68) {
                                setShowAccName(true);
                            }
                        }
                    });
                }
            } 
            else {
                dispatch(props.setDataAlert({
                    type: "warning",
                    title: "แจ้งเตือน",
                    text: "กรุณากรอกข้อมูล เบอร์โทรศัพท์ และเลขบัญชีธนาคาร !",
                    action: true,
                    redirect: "",
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        }
        else {
            dispatch(props.setDataAlert({
                type: "warning",
                title: "แจ้งเตือน",
                text: "กรุณาเลือกธนาคารที่ต้องการทำรายการ !",
                action: true,
                redirect: "",
                show: true,
                buttonText: "ตกลง"
            }));
        }
    };

    const clickBank = (data) => {
        setSelectBank(data);
    };

    const renderErrorPhone = () => {
        if (errors.phone?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง
                </div>
            );
        }
        else if (errors.phone?.type === "pattern") {
            return (
                <div className="validateError">
                    เบอร์โทรศัพท์ที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ต้องใส่ขีด (-) ในเบอร์โทรศัพท์
            </div>
        );
    };

    const renderErrorBankAccount = () => {
        if (errors.bank_account?.type === "pattern") {
            return (
                <div className="validateError">
                    เลขบัญชีญธนาคารที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        } 
        else if (errors.bank_account?.type === "minLength") {
            return (
                <div className="validateError">
                    กรอกเลขบัญชีธนาคารให้ถูกต้อง
                </div>
            );
        }

        return (
            <div className="validateInfo">
                ไม่ใช้อักขระพิเศษในเลขบัญชีญธนาคาร
            </div>
        );
    };

    const renderErrorBankAccountName = () => {
        if (errors.bank_account_name?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณากรอกชื่อบัญชีธนาคาร
                </div>
            );
        }
        else if (errors.bank_account_name?.type === "pattern") {
            return (
                <div className="validateError">
                    ชื่อบัญชีที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return (
            <div className="validateInfo">
                โปรดตรวจสอบชื่อ เเละหมายเลขบัญชี หากไม่ถูกต้องจะไม่สามารถฝากถอนได้
            </div>
        );
    };

    const reloadHomeData = () => {
        loadHomeData().then((data) => {
            if (data.code === 0 || data.code === 200) {
                // if (data.result.banks === null) {
                //     openBox();
                // }

                dispatch(setHomeData(data.result));
            }
        });
    }

    return (
        <>
            <SnackbarSet ref={childRef} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <br />
                        <div className={formStyles.inputPaddingRegisterTop}>
                            <div className={formStyles.inputTextLabel}>
                                เบอร์โทรศัพท์*
                            </div>
                            <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                <Grid item xs={2}>
                                    <PhoneIcon className={formStyles.iconSize} />
                                </Grid>
                                <Grid item xs={10}>
                                    <input id="register_phone" type="tel" defaultValue={props.register.phone} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="เบอร์โทรศัพท์" {...register("phone", { required: true, maxLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="10" required />
                                </Grid>
                            </Grid>
                            {
                                renderErrorPhone()
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={formStyles.inputPaddingRegisterTop}>
                            <div className={formStyles.inputTextLabel}>
                                กรุณาเลือกธนาคาร
                            </div>
                            <Grid container spacing={1}>
                                {
                                    props?.bankList !== undefined && (
                                        props.bankList !== null && (
                                            props.bankList.map((item, index) => (
                                                <Grid key={index} item xs={2}>
                                                    <img className={[formStyles.imgBank, selectBank === item.bank_switfcode ? "" : formStyles.imgBankNotSelect].join(" ")} src={item.bank_img} alt="bank list" onClick={() => clickBank(item.bank_switfcode)} />
                                                </Grid>
                                            ))
                                        )
                                    )
                                }
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={formStyles.inputPaddingRegisterTop}>
                            <div className={formStyles.inputTextLabel}>
                                เลขบัญชีธนาคาร*
                            </div>
                            <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                <Grid item xs={2}>
                                    <AccountBalanceIcon className={formStyles.iconSize} />
                                </Grid>
                                <Grid item xs={10}>
                                    <input type="text" defaultValue={props.register.bank_account} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="กรอกเลขบัญชีธนาคาร" {...register("bank_account", { required: true, maxLength: 13, minLength: 10, pattern: /^[0-9]+$/i })} onKeyPress={(event) => validateInput(event, "number")} maxLength="13" required />
                                </Grid>
                            </Grid>
                            {
                                renderErrorBankAccount()
                            }
                        </div>
                    </Grid>
                    {
                        showAccName && (
                            <Grid item xs={12}>
                                <div className={formStyles.inputPaddingTop}>
                                    <div className={formStyles.inputTextLabel}>
                                        ชื่อ - นามสกุล*
                                    </div>
                                    <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")} >
                                        <Grid item xs={2}>
                                            <PersonIcon className={formStyles.iconSize} />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <input type="text" defaultValue={props.register.bank_account_name} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="ชื่อ - นามสกุล" {...register("bank_account_name", { required: true, maxLength: 50, pattern: /^[a-zA-Zก-๙ ]+$/i })} onKeyPress={(event) => validateInput(event, "")} maxLength="50" required />
                                        </Grid>
                                    </Grid>
                                    {
                                        renderErrorBankAccountName()
                                    }
                                </div>
                            </Grid>
                        )
                    }
                </Grid>

                <div className={[formStyles.inputPaddingTop, "center"].join(" ")} style={{ marginTop: "30px" }}>
                    <button id="register_button" type="submit" className={[buttonStyles.btnRedirect, buttonStyles.btnRedirectComponent].join(" ")} style={{ display: "inline-block" }}>
                        <div style={{ padding: "4px 0px" }}>
                            สมัครสมาชิก
                        </div>
                    </button>
                </div>
            </form>
        </>
    );
}