import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { QRCodeCanvas } from "qrcode.react";

// component
import ModalApp from "./Modal";
import { Grid } from '@material-ui/core';

//  Redux
import { setDataPopupContact } from "./../../../../@core/redux/actions/app/popup";

//  CSS
import buttonStyles from "./../assets/css/Button.module.css";

const PopupContact = ({ popupContactShow }) => {
    const [urlLineContact, setUrlLineContact] = useState(null);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const dispatch = useDispatch();

    const onShow = () => {
        dispatch(setDataPopupContact(false));
    };

    useEffect(() => {
        if (dataSetting?.agent_contact) {
            const dataFilter = dataSetting.agent_contact.filter((item) => item?.contact_channel === 0);

            if (dataFilter.length > 0) {
                setUrlLineContact(dataFilter[0]?.channel_link);
            }
        }
    }, [dataSetting]);

    return (popupContactShow && urlLineContact) && (
        <a href={urlLineContact} target='_blank' onClick={onShow}>
            <ModalApp show={popupContactShow} onShow={onShow}>
                <div className="popupLineTitle center">
                    แอดไลน์
                </div>

                <div className="popupLineDetail center">
                    เเอดไลน์สำหรับไปยังทางเข้าเล่น
                    หรือติดต่อเเจ้งปัญหาต่าง ๆ ตลอด 24 ชม.
                </div>

                <div className="popupLineQRCode center">
                    <div className="popupLineQRCodeBG">
                        <QRCodeCanvas value={urlLineContact} size={210} />
                    </div>

                    <div className="popupLineIconPosition">
                        <img className="popupLineIcon" src="/images/icon/v3/line.png" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                    </div>
                </div>

                <div className="popupLineQRCode center">
                    <button className={`${[buttonStyles.btnLogin, buttonStyles.btnLine].join(" ")}`} style={{ border: "0px" }}>
                        <Grid container alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item>
                                <img className="popupLineIconBtn" src="/images/icon/line_white.webp" alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                            </Grid>
                            <Grid item>
                                แอดไลน์
                            </Grid>
                        </Grid>
                    </button>
                </div>

            <br />
            </ModalApp>
        </a>
    );
}

export default PopupContact;