import { useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Redux
import { openAuthenApp } from "./../../../../../../../@core/redux/actions/auth/index";
import { setDataAlert } from "../../../../../../../@core/redux/actions/app/alert";
import { setStatusSpinner } from "../../../../../../../@core/redux/actions/app/spinner";
import { setDepositData } from "../../../../../../../@core/redux/actions/deposit/index";

// Hooks
// import useParameterUrl from "./../../../../../../../@utility/hooks/useParameterUrl";
// import useContact from "./../../../../../../../@utility/hooks/useContact";

// Services
import {
    confirmUploadCheckSlip,
    loadDepositFirst
} from "../../../../../../../@core/services/depositService";
// import { loadHomeData } from "../../../../../../../@core/services/homeService";

//  CSS
import styles from "./../../../../assets/css/Style.module.css";
import modalStyles from "./../../../../assets/css/Modal.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Components  
// import CloseButton from "./../../../../components/CloseButton";
import SnackbarSet from "../../../../components/SnackbarSet";
import BackButton from "./../../component/BackButton";

// Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export default function PendingSlip({ depositItems, isUserLoggedIn }) {
    const childRef = useRef();
    const dispatch = useDispatch();
    let snackbarJson = {};
    const [imageUpload, setImageUpload] = useState(null);
    // const [checkSuccessState, setCheckSuccessState] = useState(1);
    const history = useHistory();

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    const onImageChange = (e) => {
        let imageData = e.target.files;
        if (imageData.length > 0) {
            if (imageData[0].type === "image/png" || imageData[0].type === "image/jpeg") {
                setImageUpload(imageData[0]);
            }
            else {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "ไฟล์รูปภาพไม่ถูกต้อง กรุณาเลือกรูปภาพใหม่อีกครั้ง !"
                };

                openBox(snackbarJson);
            }
        }
        else {
            setImageUpload(null);
        }
    };

    const reloadData = () => {
        loadDepositFirst().then((data) => {
            const statusCode = data.code;

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDepositData(data.result));
                history.push("/deposit/pending/auto");
            }
        });
    };

    const confirmUploadSlip = () => {
        if (depositItems?.mode === "deposit") {
            if (imageUpload === null) {
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "warning",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "กรุณาเลือกรูปภาพสลิปโอนเงิน !"
                };

                openBox(snackbarJson);
            }
            else {
                const dataUpload = new FormData();
                dataUpload.append('slip', imageUpload);

                dispatch(setStatusSpinner({ status: true, textProcess: null }));
                confirmUploadCheckSlip(dataUpload).then((data) => {
                    dispatch(setStatusSpinner({ status: false, textProcess: null }));

                    const statusCode = data.code;
                    if (statusCode === 200 || statusCode === 0) {
                        reloadData();

                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "success",
                            titleAlert: "แจ้งเตือน",
                            textAlert: "อัพโหลดข้อมูลสลิปเติมเงินสำเร็จ !"
                        };

                        openBox(snackbarJson);
                        // setCheckSuccessState(2);
                    }
                    else {
                        snackbarJson = {
                            statusShow: true,
                            statusAlert: "warning",
                            titleAlert: "แจ้งเตือน",
                            textAlert: data.message
                        };

                        openBox(snackbarJson);
                        // setCheckSuccessState(1);
                    }
                });
            }
        }
    };

    const renderContent = () => {
        return (
            <div className={styles.boxDetailTop}>
                <div className={styles.boxMarginTop}>
                    <div className={styles.boxAccountAgentTitle}>
                        อัพโหลดสลิปโอนเงิน
                    </div>
                    <div className={styles.depositDate} style={{ marginTop: "6px" }}>
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <CloudUploadIcon></CloudUploadIcon>
                            </Grid>
                            <Grid item xs={10}>
                                <div className={styles["file-upload-wrapper"]} data-text={imageUpload !== null ? imageUpload.name : "ไฟล์รูปภาพสลิป"}>
                                    <input name="file-upload-field" type="file" className={styles["file-upload-field"]} onChange={onImageChange} accept="image/png, image/jpeg" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <br />
                <div className={"left"}>
                    <div className={styles.bankPromotionComment}>
                        * เลือกสลิปโอนเงินที่ต้องการอัพโหลด และรอเวลาตรวจสอบข้อมูล 5 - 30 วินาที
                    </div>
                </div>
            </div>
        );
        // if (checkSuccessState === 2) {
        //     return (
        //         <div className={[styles.boxDetailTop, "center"].join(" ")}>
        //             <div className="svg-box" style={{ marginTop: "0px" }}>
        //                 <svg className="circular green-stroke">
        //                     <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
        //                 </svg>
        //                 <svg className="checkmark green-stroke">
        //                     <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
        //                         <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
        //                     </g>
        //                 </svg>
        //             </div>
        //             <div className={styles.confirmText} style={{ color: "black", fontWeight: "700" }}>
        //                 ข้อมูลสลิปเติมเงินถูกต้อง ระบบได้ทำการเติมเครดิตเรียบร้อยแล้ว
        //             </div>
        //         </div>
        //     );
        // }
        // else if (checkSuccessState === 1) {
        //     return (
        //         <div className={styles.boxDetailTop}>
        //             <div className={styles.boxMarginTop}>
        //                 <div className={styles.boxAccountAgentTitle}>
        //                     อัพโหลดสลิปโอนเงิน
        //                 </div>
        //                 <div className={styles.depositDate} style={{ marginTop: "6px" }}>
        //                     <Grid container alignItems="center">
        //                         <Grid item xs={2}>
        //                             <CloudUploadIcon></CloudUploadIcon>
        //                         </Grid>
        //                         <Grid item xs={10}>
        //                             <div className={styles["file-upload-wrapper"]} data-text={imageUpload !== null ? imageUpload.name : "ไฟล์รูปภาพสลิป"}>
        //                                 <input name="file-upload-field" type="file" className={styles["file-upload-field"]} onChange={onImageChange} accept="image/png, image/jpeg" />
        //                             </div>
        //                         </Grid>
        //                     </Grid>
        //                 </div>
        //             </div>
        //             <br />
        //             <div className={"left"}>
        //                 <div className={styles.bankPromotionComment}>
        //                     * เลือกสลิปโอนเงินที่ต้องการอัพโหลด และรอเวลาตรวจสอบข้อมูล 3 - 10 วินาที
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        // else {
        //     return null;
        // }
    };

    const gotoLogin = () => {
        dispatch(openAuthenApp({ tab: "login", show: true }));
        // history.push("/");
    };

    return (
        <>
            <SnackbarSet ref={childRef} />

            <div className={modalStyles.backButtonFull}>
                <BackButton backPage={"/deposit/home"}></BackButton>
            </div>
            <div className={modalStyles.boxTitle}>
                ตรวจสอบสลิปเติมเงิน
            </div>

            {
                isUserLoggedIn === false ?
                    <div className={styles.boxNoneBankMargin}>
                        <div className={styles.boxNoneBank}>
                            <div className={styles.boxNoneBankTitle} style={{ color: "white" }}>
                                ไม่สามารถทำรายการได้ เนื่องจากยังไม่ได้เข้าสู่ระบบ
                            </div>
                            <div className={styles.boxNoneBankGoto} onClick={() => { gotoLogin() }}>
                                คลิ๊ก ไปที่หน้าเข้าสู่ระบบ
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className={styles.boxDetailDeposit}>
                            {
                                renderContent()
                            }
                        </div>

                        <div className={styles.boxMarginFormTop}>
                            <div className="center">
                                <button id="deposit_slip_upload" className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={confirmUploadSlip}>
                                    อัพโหลดสลิป
                                </button>
                            </div>
                        </div>
                    </>
            }
        </>
    );
}