import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

// auth
// import { isUserLoggedIn } from "./../../../../../../../@core/auth/utils";

// Services
import { updatePromotionAuto } from "./../../../../../../../@core/services/promotionService";

// @Utility
import copyText from "./../../../../../../../@utility/app/copyText";
// import currencyFormat from "./../../../../../../../@utility/numbers/currencyFormat";

// CSS
import styles from "./../../../../assets/css/Style.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Component
import Switch from "./../../component/Switch";

export default function BoxAuto({ depositItems, openBox }) {
    const [promotionSwitch, setPromotionSwitch] = useState(null);
    const homeData = useSelector(state => state.reduxHome.result);
    let snackbarJson = {};

    const copyData = (data) => {
        if (copyText(data)) {
            snackbarJson = {
                statusShow: true,
                statusAlert: "success",
                titleAlert: "แจ้งเตือน",
                textAlert: "คัดลอกสำเร็จ !"
            };

            openBox(snackbarJson);
        }
    };

    useEffect(() => {
        if (homeData !== null) {
            setPromotionSwitch(homeData.promotion_auto === 1 ? true : false);
        }
    }, [homeData]);

    useEffect(() => {
        if (promotionSwitch !== null) {
            let promotion_auto = promotionSwitch ? 1 : 0;
            updatePromotionAuto(promotion_auto).then(() => { });
        }
    }, [promotionSwitch]);

    return (
        <>
            <div className={styles.boxDetailTop}>
                <div className={styles.boxAccountAgentTitle}>
                    โอนเงินมาที่บัญชี
                </div>
                <div className={[styles.boxDetailAmount, styles.boxDetailAgent].join(" ")} style={{ marginTop: "0px" }}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={2}>
                            <img src={depositItems?.agent_bankimg} style={{ width: "100%", borderRadius: "12px" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                        </Grid>
                        <Grid item xs={10}>
                            <div className={styles.boxAccountAgentText} style={{ fontWeight: "600" }}>
                                {depositItems?.agent_bankname}
                                <br />
                                ชื่อบัญชี  : {depositItems?.agent_bank_account_name}
                                <br />
                                เลขบัญชี : {depositItems?.agent_bank_account}
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                    <div className="center">
                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnCopy].join(" ")} onClick={() => copyData(depositItems?.agent_bank_account)}>
                            คัดลอกเลขบัญชี
                        </button>
                    </div>
                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                        <div className={styles.bankPromotionComment}>
                            ** กรุณาใช้บัญชี <span style={{ fontWeight: "bold" }}>"{depositItems?.customer_bank_account} - {depositItems?.customer_bank_account_name}"</span> โอนเงินมาเท่านั้น **
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.boxMarginTop}>
                <div className={styles.boxDetailTop}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <div className={styles.boxAccountAgentTitle}>
                                เลือกรับโปรโมชั่นอัตโนมัติ
                            </div>
                        </Grid>
                        <Grid item>
                            <Switch
                                isOn={promotionSwitch}
                                onColor="#38C172"
                                handleToggle={() => setPromotionSwitch(!promotionSwitch)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}