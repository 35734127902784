import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// import { useHistory } from 'react-router-dom';

// auth
import { isUserLoggedIn } from "./../../../../../@core/auth/utils";

// @utility
import alertText from "./../../../../../@utility/app/alertText";
import validateInput from "./../../../../../@utility/app/validateInput";

// Services
import { login, loginLine } from "./../../../../../@core/auth/jwt/jwtService";
import { loadUrlLineLogin } from "./../../../../../@core/services/appService";
import { loadHomeData } from "./../../../../../@core/services/homeService";

// Redux
// import { openAuthenApp } from "./../../../../../@core/redux/actions/auth/index";
import { setHomeData } from "./../../../../../@core/redux/actions/home/index";
import { setShowPopupLogin } from "./../../../../../@core/redux/actions/app/popup";
// import { setDataDialog } from "./../../../../../@core/redux/actions/app/dialog";

//  CSS
import styles from "./../../assets/css/Style.module.css";
// import modalStyles from "./../../assets/css/Modal.module.css";
import formStyles from "./../../assets/css/Input.module.css";
import buttonStyles from "./../../assets/css/Button.module.css";

// Component
// import SnackbarSet from "./../SnackbarSet";

// Icon
import AccountCircle from '@material-ui/icons/AccountCircle';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import IconButton from- '@material-ui/core/IconButton';

export default function LoginApp(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const dispatch = useDispatch();
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const [jsonContact, setJsonContact] = useState(null);
    // const history = useHistory();
    let snackbarJson = {};
    const [password, setPassword] = useState({
        password: "",
        showPassword: false
    });

    // แสดงหรือซ่อนรหัสผ่าน
    const handleClickShowPassword = () => {
        setPassword({ ...password, showPassword: !password.showPassword });
    };

    // ปิด modal
    const close = () => {
        props.onShow(false)
    };

    const onSubmit = (data) => {
        let myJson = {
            "username": data.username,
            "password": data.password,
            "verify": ""
        }

        login(myJson).then((data) => {
            const statusCode = data;
            if (statusCode === 200 || statusCode === 0) {
                reloadHomeData();
                snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "เข้าสู่ระบบสำเร็จ !"
                };

                props.alert(snackbarJson);
                isUserLoggedIn();

                dispatch(setShowPopupLogin(true));
                close()
            }
            else {
                if (statusCode === 2 || statusCode === 4) {
                    snackbarJson = alertText(statusCode);
                    props.alert(snackbarJson);

                    setTimeout(function () {
                        window.location.href = `/`;
                    }, 5000);
                }
                else {
                    snackbarJson = alertText(statusCode);
                    props.alert(snackbarJson);
                }
            }
        });
    };

    const reloadHomeData = () => {
        loadHomeData().then((data) => {
            if (data.code === 0 || data.code === 200) {
                // if (data.result.banks === null) {
                //     openBox();
                // }

                dispatch(setHomeData(data.result));
            }
        });
    }

    const renderErrorUsername = () => {
        if (errors.username?.type === "pattern") {
            return (
                <div className="validateError">
                    ยูสเซอร์เนมที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return null;
    };

    const renderErrorPassword = () => {
        if (errors.password?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุรหัสผ่านมากกว่า 4 ตัวอักษร
                </div>
            );
        }

        return null;
    };

    // const openBox = () => {
    //     let dialogJson = {
    //         pageType: "bankAccount",
    //         statusShow: true,
    //         type: "text",
    //         image: `${window.location.protocol}//${window.location.host}/images/icon/bell.png`,
    //         title: "แจ้งเตือน",
    //         content: "คุณยังไม่มีบัญชีเตินเงิน, ต้องการเพิ่มบัญชีเติมเงิน หรือไม่ ?",
    //         action: null
    //     };

    //     dispatch(setDataDialog(dialogJson));
    // };

    // คลิกเข้าระบบไลน์
    const gotoLinkLine = () => {
        if (jsonContact) {
            window.open(jsonContact?.channel_link, "_blank");
        }
    };

    // สู่ระบบผ่านไลน์
    const authLine = (code) => {
        let myJson = {
            "code": code,
            "url": `${window.location.protocol}//${window.location.host}?login=true`
        }

        loginLine(myJson).then((data) => {
            const statusCode = data.code;
            const result = data.result;

            if (statusCode === 200 || statusCode === 0) {
                if (result?.username === undefined || result === null || result === undefined) {
                    // มีบัญชีอยู่ในระบบ
                    reloadHomeData();
                    snackbarJson = { statusShow: true, statusAlert: "success", titleAlert: "แจ้งเตือน", textAlert: "เข้าสู่ระบบสำเร็จ !" }
                    props.alert(snackbarJson);
                    props.onProcess(false);
                    props.onShow(false);
                }
                else {
                    // ยังไม่มีบัญชีในระบบ
                    props.onData(result);
                    props.switchChanel('register');
                    props.onProcess(false);
                }

            }
            else {
                // เกิดข้อผิดพลาดในการเข้าระบบไลน์
                if (statusCode === 3) {
                    snackbarJson = { statusShow: true, statusAlert: "warning", titleAlert: "แจ้งเตือน", textAlert: "ไม่สามารถเข้าสู่ระบบด้วยไลน์ได้ กรุณาสมัครสมาชิก" }
                    props.alert(snackbarJson);
                }
                else {
                    snackbarJson = { statusShow: true, statusAlert: "warning", titleAlert: "แจ้งเตือน", textAlert: "เกิดข้อผิดพลาดในเข้าระบบไลน์ !" }
                    props.alert(snackbarJson);
                }

                props.onProcess(false);
            }
        });
    };

    useEffect(() => {

        if (!isUserLoggedIn()) {
            // เช็คการเข้าสู่ระบบผ่านไลน์
            if (props.codeLine !== undefined && props.codeLine !== "") {
                props.onProcess(true);
                authLine(props.codeLine);
            }
        }

    }, [props.codeLine]);

    useEffect(() => {
        const contactData = dataSetting?.agent_contact;
        if (contactData) {
            const lineData = contactData.find((item) => item?.contact_channel === 0);

            if (lineData) {
                setJsonContact(lineData);
            }
        }
    }, [dataSetting]);

    return (
        !props.process ?
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={formStyles.inputPaddingTop}>
                    <br />
                    <div className={formStyles.inputTextLabel}>
                        ยูสเซอร์เนม
                    </div>
                    <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")}>
                        <Grid item xs={2}>
                            <AccountCircle className={formStyles.iconSize} />
                        </Grid>
                        <Grid item xs={10}>
                            <input type="text" className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="ยูสเซอร์เนม" {...register("username", { required: true, maxLength: 20, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="20" required />
                        </Grid>
                    </Grid>
                    {
                        renderErrorUsername()
                    }
                </div>
                <div className={formStyles.inputPaddingTop}>
                    <div className={formStyles.inputTextLabel}>
                        รหัสผ่าน
                    </div>
                    <Grid container alignItems="center" spacing={1} className={[formStyles.inputStyles, "center"].join(" ")}>
                        <Grid item xs={2}>
                            <LockIcon className={formStyles.iconSize} />
                        </Grid>
                        <Grid item xs={8}>
                            <input type={password.showPassword ? 'text' : 'password'} className={[formStyles.inputStyles, "kanit"].join(" ")} placeholder="รหัสผ่าน"  {...register("password", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="20" required />
                        </Grid>
                        <Grid item xs={2}>
                            <div
                                // aria-label="สลับการมองเห็นรหัสผ่าน"
                                onClick={handleClickShowPassword}
                            >
                                {password.showPassword ? <Visibility className={formStyles.iconSize} /> : <VisibilityOff className={formStyles.iconSize} />}
                            </div>
                        </Grid>
                    </Grid>
                    {
                        renderErrorPassword()
                    }
                </div>
                <div className={[formStyles.inputPaddingTop, "right"].join(" ")}>
                    <div className={formStyles.inputTextLabel} style={{ paddingBottom: "0px", borderBottom: "1px solid #FF0000", display: "inline-block", paddingLeft: "0px", color: "#FF0000", cursor: "pointer" }} onClick={() => { props.switchChanel("remember") }}>
                        ลืมรหัสผ่านใช่หรือไม่ ?
                    </div>
                </div>


                <div className="center">
                    <div className={formStyles.inputPaddingTop} style={{ paddingTop: "10px" }}>
                        <button id="login_button" type="submit" className={buttonStyles.btnConfirm}>
                            เข้าสู่ระบบ
                        </button>
                    </div>

                    {
                        jsonContact && (
                            <>
                                <div className={formStyles.borderSocial}>
                                    <div className={formStyles.orPositionCenter}>
                                        <div className={formStyles.orText}>
                                            หรือ
                                        </div>
                                    </div>
                                </div>

                                <div className={formStyles.inputPaddingTop}>
                                    <div className={[buttonStyles.btnLogin, buttonStyles.btnLine, buttonStyles.btnContactSize].join(" ")} onClick={gotoLinkLine}>
                                        <Grid container alignItems="center" justifyContent="center" spacing={4} style={{ height: "100%" }}>
                                            <Grid item>
                                                <img src="/images/icon/line_white.webp" className={[styles.iconSizeSub, formStyles.iconSizeSub].join(" ")} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                            </Grid>
                                            <Grid item>
                                                เข้าสู่ระบบด้วยไลน์
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </div>
            </form>
            :
            null
    );
}