import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

//  CSS
import styles from "./../../../assets/css/Style.module.css";
import modalStyles from "./../../../assets/css/Modal.module.css";

export default function Affiliate(props) {
    const affliateData = useSelector(state => state.reduxAffiliate.result);
    const reduxAppCMS = useSelector(state => state.reduxAppCMS.cms);

    const useStyles = makeStyles((theme) => ({
        menuText: {
            fontSize: "20px",
            color: props.reduxAppCMS !== null ? props.reduxAppCMS["bt-defult"] : "white"
        },
        menuList: {
            background: props.reduxAppCMS !== null ? props.reduxAppCMS["bt-undefult"] : "black",
            borderRadius: "10px",
            padding: "40px 20px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            height: "120px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "relative",
            '&:hover': {
                top: "2px"
            },
            [theme.breakpoints.down('xs')]: {
                padding: "20px"
            }
        },
        imgSize: {
            width: "100px",
            height: "auto"
        },
        menuComment: {
            fontSize: "18px",
            color: props.reduxAppCMS !== null ? props.reduxAppCMS["bt-defult"] : "white"
        },
    }));

    const classes = useStyles();

    const gotoComponent = (property_type, data) => {
        props.setMenuSelect(property_type);
        props.setDataArr(data);
    };

    // reduxAppCMS?.section_hide_percent_caskback === "1"
    console.log("reduxAppCMS?.section_hide_percent_caskback : ", ((reduxAppCMS?.section_hide_percent_caskback === "1" || reduxAppCMS?.section_hide_percent_caskback === 1) ? "" : `100%`));

    const renderTextPercent = (percent_com, percent_cashback) => {
        if (percent_com > 0 && percent_cashback <= 0) {
            return (
                <>
                    <div className={classes.menuText}>
                        ค่าคอม {(reduxAppCMS?.section_hide_percent_com === "1" || reduxAppCMS?.section_hide_percent_com === 1) ? "" : `${percent_com}%`}
                    </div>
                    <div className={classes.menuText}>
                        &nbsp;
                    </div>
                </>
            );
        }
        else if (percent_com <= 0 && percent_cashback > 0) {
            return (
                <>
                    <div className={classes.menuText}>
                        คืนยอดเสีย {(reduxAppCMS?.section_hide_percent_caskback === "1" || reduxAppCMS?.section_hide_percent_caskback === 1) ? "" : `${percent_cashback}%`}
                    </div>
                    <div className={classes.menuText}>
                        &nbsp;
                    </div>
                </>
            );
        }
        else {
            return (
                <>
                    <div className={classes.menuText}>
                        คืนยอดเสีย {(reduxAppCMS?.section_hide_percent_caskback === "1" || reduxAppCMS?.section_hide_percent_caskback === 1) ? "" : `${percent_cashback}%`}
                    </div>

                    <div className={classes.menuText}>
                        ค่าคอม {(reduxAppCMS?.section_hide_percent_com === "1" || reduxAppCMS?.section_hide_percent_com === 1) ? "" : `${percent_com}%`}
                    </div>
                </>
            );
        }
    };

    useEffect(() => {
        if (affliateData !== null) {
            if (affliateData.length === 1) {
                props.setMenuSelect(affliateData[0].property_type);
                props.setDataArr(affliateData[0]);
            }
        }
    }, [affliateData]);

    return (
        <>
            <div className={modalStyles.boxTitle}>
                รับทรัพย์
            </div>
            <div className={styles.boxMarginTop}>
                <div className={[styles.boxContentTitle, "center"].join(" ")}>
                    กดเลือกรายการรับทรัพย์ที่ต้องการ
                </div>
            </div>
            <div className={styles.boxMarginFormTop}>
                <Grid container spacing={2} alignItems="center">
                    {
                        affliateData !== null && (
                            affliateData.map((item) => (
                                <Grid key={item.property_type} item xs={6}>
                                    <div className="center">
                                        <div className={classes.menuText} style={{ fontWeight: "600", color: props.reduxAppCMS !== null ? props.reduxAppCMS["bt-warning"] : "white" }}>
                                            {item.property_name}
                                        </div>
                                        <div className={styles.boxMarginMediumTop}>
                                            <div className={classes.menuList} onClick={() => gotoComponent(item.property_type, item)}>
                                                <div>
                                                    <img src={item.property_image} className={classes.imgSize} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.boxMarginMediumTop}>
                                            {
                                                renderTextPercent(item.property_commission.percent, item.property_cashback.percent)
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            ))
                        )
                    }
                </Grid>
            </div>
            <div className={[styles.boxMarginFormTop, "center"].join(" ")}>
                <div className={[styles.center, styles.white].join(" ")}>
                    หากพบปัญหากรุณาติดต่อ <span className={styles.contactService}>พนักงานบริการลูกค้า</span>
                </div>
            </div>
            <br />
        </>
    );
}


// import { useState, useRef } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import * as clipboard from 'clipboard-polyfill/text';
// import { Grid } from '@material-ui/core';
// import { useDispatch } from 'react-redux';

// // Redux
// import { setDataAlert } from "./../../../../../../@core/redux/actions/app/alert";
// import { setDataCondition } from "../../../../../../@core/redux/actions/app/conditions";

// // Component
// import SnackbarSet from '../../../components/SnackbarSet';
// import BackButton from '../../../components/BackButton';
// import ComissionList from "./ComissionList";

// // @utility
// import currencyFormat from "../../../../../../@utility/numbers/currencyFormat";

// //  CSS
// import styles from "./../../../assets/css/Style.module.css";
// import modalStyles from "./../../../assets/css/Modal.module.css";

// export default function Affiliate({ affliateData }) {
//     const childRef = useRef();
//     const [comStatus, setComStatus] = useState(false);
//     // let snackbarJson = {};
//     const dispatch = useDispatch();

//     const useStyles = makeStyles((theme) => ({
//         boxPadding: {
//             padding: "12px 0px",
//             borderRadius: "6px",
//             fontSize: "18px",
//             color: "white"
//         },
//         boxBg: {
//             background: "#12012A",
//             color: "#858585",
//         },
//         socialBorder: {
//             border: "solid 2px white",
//             borderRadius: "6px",
//             padding: "0px 6px",
//             height: "64px",
//             [theme.breakpoints.down('xs')]: {
//                 height: "50px"
//             }
//         },
//         socialSize: {
//             position: "relative",
//             width: "100%",
//             height: "auto",
//             marginTop: "8px",
//             cursor: "pointer",
//             '&:hover': {
//                 top: "2px"
//             }
//         },
//         btnCopy: {
//             position: "relative",
//             height: "64px",
//             lineHeight: "64px",
//             borderRadius: "6px",
//             border: "solid 2px transparent",
//             background: "linear-gradient(169.65deg, #FFF960 -34.09%, #FF8C22 100.99%)",
//             color: "#140041",
//             fontSize: "22px",
//             fontWeight: "bold",
//             cursor: "pointer",
//             '&:hover': {
//                 top: "2px"
//             },
//             [theme.breakpoints.down('xs')]: {
//                 fontSize: "16px",
//                 height: "50px",
//                 lineHeight: "50px"
//             }
//         },
//         boxAmount: {
//             height: "52px",
//             lineHeight: "52px",
//             borderRadius: "6px",
//             fontSize: "22px",
//             border: "solid 2px transparent",
//             color: "white",
//             [theme.breakpoints.down('xs')]: {
//                 fontSize: "18px",
//                 height: "46px",
//                 lineHeight: "46px"
//             }
//         },
//         btnCommission: {
//             height: "52px",
//             lineHeight: "52px",
//             background: "linear-gradient(180deg, #FF0000 0%, #CB0000 100%)",
//             color: "white",
//             [theme.breakpoints.down('xs')]: {
//                 height: "46px",
//                 lineHeight: "46px"
//             }
//         },
//         titleBox: {
//             fontSize: "20px",
//             color: "white",
//             marginBottom: "12px",
//             textAlign: "left"
//         }
//     }));

//     const classes = useStyles();

//     const gotoShare = (url) => {
//         window.open(url, "_blank");
//     };

//     const copyUrl = () => {
//         clipboard.writeText(affliateData.shared_link).then(
//             function () {
//                 dispatch(setDataAlert({
//                     type: "success",
//                     title: "แจ้งเตือน",
//                     text: "คัดลอกลิงค์สำเร็จ !",
//                     action: false,
//                     redirect: "",
//                     show: true,
//                     buttonText: ""
//                 }));
//                 // snackbarJson = {
//                 //     statusShow: true,
//                 //     statusAlert: "success",
//                 //     titleAlert: "แจ้งเตือน",
//                 //     textAlert: "คัดลอกลิงค์สำเร็จ !"
//                 // };

//                 // childRef.current.snackbar(snackbarJson);
//             },
//             function () {
//                 console.log("error!");
//             }
//         );
//     };

//     const openCondition = () => {
//         let myJson = {
//             statusShow: true,
//             title: "ลิงค์รับทรัพย์",
//             contentType: "commission"
//         };

//         dispatch(setDataCondition(myJson));
//     };

//     return (
//         <>
//             <SnackbarSet ref={childRef} />

//             {
//                 comStatus === false ?
//                     <>
//                         <div className={modalStyles.boxTitle}>
//                             ลิงค์รับทรัพย์
//                         </div>

//                         <div className="center">
//                             <span className={styles.titleRule} onClick={openCondition}>
//                                 เงื่อนไขลิงค์รับทรัพย์
//                             </span>
//                         </div>

//                         <div className={styles.boxMarginFormTop}>
//                             <img src="/images/home/affiliate.webp" style={{ borderRadius: "16px", width: "100%" }} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
//                         </div>

//                         <div className={[classes.boxPadding, classes.boxBg, "center", styles.boxMarginTop].join(" ")}>
//                             {affliateData !== null ? affliateData.shared_link : "-"}
//                         </div>
//                         <div className={[classes.boxPadding, "center", styles.boxMarginTop].join(" ")}>
//                             <Grid container spacing={1}>
//                                 <Grid item xs={7} sm={6}>
//                                     <div className={classes.socialBorder}>
//                                         <Grid container alignItems="center">
//                                             <Grid item xs={6}>
//                                                 แชร์ลิงค์
//                                             </Grid>
//                                             <Grid item xs={6}>
//                                                 <Grid container spacing={1}>
//                                                     <Grid item xs={6}>
//                                                         <img src="/images/button/share_fb.webp" className={classes.socialSize} onClick={() => gotoShare(affliateData.shared_facebook)} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
//                                                     </Grid>
//                                                     <Grid item xs={6}>
//                                                         <img src="/images/button/share_line.webp" className={classes.socialSize} onClick={() => gotoShare(affliateData.shared_line)} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
//                                                     </Grid>
//                                                 </Grid>
//                                             </Grid>
//                                         </Grid>
//                                     </div>
//                                 </Grid>
//                                 <Grid item xs={5} sm={6}>
//                                     <div className={classes.btnCopy} onClick={copyUrl}>
//                                         คัดลอกลิงค์
//                                     </div>
//                                 </Grid>
//                             </Grid>
//                         </div>
//                         <div className={["center", styles.boxMarginTop].join(" ")}>
//                             <div className={classes.titleBox}>
//                                 ค่าคอมมิชชั่น
//                             </div>
//                             <Grid container spacing={1}>
//                                 <Grid item xs={8}>
//                                     <div className={[classes.boxBg, classes.boxAmount].join(" ")}>
//                                         {currencyFormat((affliateData !== null ? affliateData.commission : 0), 2)}
//                                     </div>
//                                 </Grid>
//                                 <Grid item xs={4}>
//                                     <div className={[classes.btnCopy, classes.btnCommission].join(" ")} onClick={() => { setComStatus(true) }}>
//                                         รับค่าคอม
//                                     </div>
//                                 </Grid>
//                             </Grid>
//                         </div>
//                         <br />
//                     </>
//                     :
//                     <>
//                         <div className={modalStyles.backButton} onClick={() => { setComStatus(false) }}>
//                             <BackButton></BackButton>
//                         </div>

//                         <ComissionList></ComissionList>
//                     </>
//             }
//         </>
//     );
// }
