import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { QRCodeCanvas } from "qrcode.react";
import html2canvas from "html2canvas";

// Redux
// import { openPromotionPopup } from "../../../../../../../@core/redux/actions/promotion/index";
import { setDataContact } from "../../../../../../../@core/redux/actions/app/contact";

// Services
import { updatePromotionAuto } from "./../../../../../../../@core/services/promotionService";


// @Utility
import copyText from "./../../../../../../../@utility/app/copyText";
import { getStorage, setStorage } from "../../../../../../../@utility/app/storage";
import currencyFormat from "../../../../../../../@utility/numbers/currencyFormat";

// CSS
import styles from "./../../../../assets/css/Style.module.css";
import buttonStyles from "./../../../../assets/css/Button.module.css";

// Component
import Switch from "../../component/Switch";

export default function BoxResultPromptPay({ depositItems, timeShow, timeDepositProcess, channelInfo, openBox }) {
    const dispatch = useDispatch();
    const [promotionSwitch, setPromotionSwitch] = useState(false);
    const homeData = useSelector(state => state.reduxHome.result);
    const dataSetting = useSelector(state => state.reduxAppMenu.menu_items);
    const [payMode, setPayMode] = useState("");
    const [payAccount, setPayAccount] = useState(null);
    const [paySrc, setPaySrc] = useState("");
    const qrRef = useRef(null);

    const explodeDecimal = (decimal) => {
        let decimalSplit = decimal.split(".");

        return <>
            {decimalSplit[0]}.<span style={{ color: "#FFE600" }}>{decimalSplit[1]}</span>
        </>
    };

    const explodeDecimalOther = (decimal) => {
        let decimalSplit = decimal.split(".");

        return <>
            {decimalSplit[0]}.{decimalSplit[1]}
        </>
    };

    const newTabUrlAkPay = () => {
        const depositPromptPayTime = getStorage("depositPromptPayTime");

        if (!depositPromptPayTime) {
            let firstEventTimestamp = Date.now();
            let timeStart = firstEventTimestamp + (5 * 60 * 1000);
            setStorage("depositPromptPayTime", timeStart);
        }

        window.location.href = paySrc;
    };

    const openContact = () => {
        dispatch(setDataContact(true));
    };

    const downloadQRCode = () => {
        if (!timeDepositProcess) return null;

        if (qrRef.current) {
            html2canvas(qrRef.current, { backgroundColor: null }).then((canvas) => {
                const url = canvas.toDataURL("image/png");

                // Detect if it's iOS Safari
                const isIOS = /iP(hone|od|ad)/.test(navigator.userAgent);
                const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                if (isIOS && isSafari) {
                    // เปิดภาพในแท็บใหม่
                    const newTab = window.open();
                    newTab.document.write('<img src="' + url + '" width="100%"/>');
                } else {
                    // ดาวน์โหลดอัตโนมัติใน Browser อื่น ๆ
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = `${depositItems?.customer_bank_account}-${new Date().getTime()}-qrcode.png`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            });
        }
    };

    const copyData = (data) => {
        if (copyText(data)) {
            let snackbarJson = {
                statusShow: true,
                statusAlert: "success",
                titleAlert: "แจ้งเตือน",
                textAlert: "คัดลอกสำเร็จ !"
            };

            openBox(snackbarJson);
        }
    };

    const readIconBank = (keyword, eventSelect) => {
        let dataSrc = "";

        if (dataSetting?.agent_setting_bank) {
            const result = dataSetting.agent_setting_bank.find(
                (bank) => bank.bank_short.toLowerCase().includes(keyword.toLowerCase())
            );

            if (result) {
                if (eventSelect === "name") {
                    dataSrc = result?.bank_name;
                }

                if (eventSelect === "image") {
                    dataSrc = result?.bank_img;
                }
            }
            else {
                if (eventSelect === "name") {
                    dataSrc = keyword;
                }

                if (eventSelect === "image") {
                    dataSrc = channelInfo?.picture;
                }
            }
        }
        else {
            if (eventSelect === "name") {
                dataSrc = keyword;
            }

            if (eventSelect === "image") {
                dataSrc = channelInfo?.picture;
            }
        }

        return dataSrc;
    };

    const renderContentIframe = () => {
        if (paySrc) {
            if (payMode === "iframe") {
                return (
                    <div className={styles.boxDetailTop}>
                        <iframe
                            title="iframe"
                            className={styles.qrCodePromptPay}
                            src={paySrc}
                            scrolling="no"
                            frameBorder="0"
                        />
                    </div>
                );

            }

            if (payMode === "link-tab") {
                return (
                    <>
                        {
                            channelInfo?.event_select === "number" && (
                                <div className={styles.boxDetailTop} style={{ borderBottom: "2px dashed #000000" }}>
                                    <div className={styles.boxAccountAgentTitle}>
                                        จำนวนเงินที่ต้องโอน
                                    </div>

                                    <div className={styles.boxMarginTop}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={5}>
                                                ยอดทั้งหมด
                                            </Grid>
                                            <Grid item xs={5}>
                                                <div className={`${styles.boxDetailAmountNumber} ${styles.boxDetailAmountNumberText}`}>
                                                    {
                                                        explodeDecimal(currencyFormat(depositItems?.amount, 2))
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={2}>
                                                บาท
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                        <div className={styles.bankPromotionComment}>
                                            ** กรุณาใช้บัญชี <span style={{ fontWeight: "bold" }}>"{depositItems?.customer_bank_account} - {depositItems?.customer_bank_account_name}"</span> โอนเงินมาเท่านั้น **
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <div className={styles.boxDetailTop}>
                            <div className="center">
                                {
                                    (!timeDepositProcess) <= 0 ?
                                        <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={openContact}>
                                            ติดต่อแอดมิน
                                        </button>
                                        :
                                        <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={() => newTabUrlAkPay()}>
                                            ดูบัญชีโอนเงิน {timeShow != 0 ? `(${timeShow})` : ""}
                                        </button>
                                }

                            </div>
                            <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                {
                                    (!timeDepositProcess) ?
                                        <div className={styles.bankPromotionComment}>
                                            ** หมดเวลาทำรายการ "กรุณาติดต่อแอดมิน เพื่อดำเนินการต่อ" **
                                        </div>
                                        :
                                        <div className={styles.bankPromotionComment}>
                                            ** กรุณากดที่ปุ่ม "ดูบัญชีโอนเงิน" เพื่อโอนเงินเข้าระบบ **
                                        </div>
                                }

                            </div>
                        </div>
                    </>
                );
            }
        }

        return null;
    };

    const renderContentNoIframe = () => {
        if (payMode === "qrcode" && paySrc) {
            return (
                <>
                    <div className={styles.boxDetailTop} style={{ borderBottom: "2px dashed #000000" }}>
                        <div className="boxAccountAgentQrcodeTitle center">
                            ดาวน์โหลดคิวอาร์โค้ด
                        </div>

                        {
                            channelInfo?.event_select === "number" && (
                                <div className="boxDetailAmountQrcodeNumber center">
                                    จำนวนเงินที่ต้องโอน <span className="boxDetailAmountQrcodeNumberText">{explodeDecimalOther(currencyFormat(depositItems?.amount, 2))}</span> บาท
                                </div>
                            )
                        }

                        <div className={[styles.boxMarginTop, "center"].join(" ")}>
                            <div className={styles.bankPromotionComment}>
                                ** กรุณาใช้บัญชี <span style={{ fontWeight: "bold" }}>"{depositItems?.customer_bank_account} - {depositItems?.customer_bank_account_name}"</span> โอนเงินมาเท่านั้น **
                            </div>
                        </div>
                    </div>

                    <div className={styles.boxDetailTop}>
                        <div className="boxDetailQRCode center">
                            <div ref={qrRef}>
                                <div className="boxDetailQRCodeScan">
                                    <div className="boxDetailQRCodeBG">
                                        <img src="/images/ci-qrpayment-img-01.png" className="iconPromtpaySize" />
                                    </div>

                                    <div className="boxDetailQRCodeGenTop">
                                        <div style={{ opacity: !timeDepositProcess ? ".5" : "1" }}>
                                            <QRCodeCanvas value={paySrc} size={200} />
                                        </div>

                                        <div className="boxDetailQRCodeScanNote">
                                            ** ใช้ทำรายการได้เพียงครั้งเดียวเท่านั้น **
                                        </div>
                                    </div>

                                    <div className="boxDetailQRCodeBGSupport">
                                        ทางเข้าเล่น {window.location.hostname}
                                    </div>
                                </div>
                            </div>

                            {
                                timeDepositProcess && (
                                    <div className="boxDetailQRCodeButtonSave">
                                        <button className={[buttonStyles.btnConfirm, buttonStyles.btnRegister].join(" ")} onClick={downloadQRCode}>
                                            ดาวน์โหลด
                                        </button>

                                        <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                            <div className={styles.bankPromotionComment}>
                                                ** กรุณาทำรายการภายใน 15 นาที **
                                            </div>
                                        </div>
                                    </div>

                                )
                            }

                        </div>

                        {
                            !timeDepositProcess && (
                                <>
                                    <br />
                                    <div className="center">
                                        <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={openContact}>
                                            ติดต่อแอดมิน
                                        </button>
                                    </div>

                                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                        <div className={styles.bankPromotionComment}>
                                            ** หมดเวลาทำรายการ "กรุณาติดต่อแอดมิน เพื่อดำเนินการต่อ" **
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </>
            );
        }

        if (payMode === "account" && payAccount) {
            return (
                <>
                    {
                        channelInfo?.event_select === "number" && (
                            <div className={styles.boxDetailTop} style={{ borderBottom: "2px dashed #000000" }}>
                                <div className={styles.boxAccountAgentTitle}>
                                    จำนวนเงินที่ต้องโอน
                                </div>

                                <div className={styles.boxMarginTop}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={5}>
                                            ยอดทั้งหมด
                                        </Grid>
                                        <Grid item xs={5}>
                                            <div className={`${styles.boxDetailAmountNumber} ${styles.boxDetailAmountNumberText}`}>
                                                {
                                                    explodeDecimal(currencyFormat(depositItems?.amount, 2))
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            บาท
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                    <div className={styles.bankPromotionComment}>
                                        ** กรุณาใช้บัญชี <span style={{ fontWeight: "bold" }}>"{depositItems?.customer_bank_account} - {depositItems?.customer_bank_account_name}"</span> โอนเงินมาเท่านั้น **
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className={styles.boxDetailTop}>
                        <>
                            <div className={styles.boxAccountAgentTitle}>
                                โอนเงินมาที่บัญชี
                            </div>
                            <div className={[styles.boxDetailAmount, styles.boxDetailAgent].join(" ")} style={{ marginTop: "0px" }}>
                                <Grid container alignItems="center" spacing={3}>
                                    <Grid item xs={2}>
                                        <img src={readIconBank(payAccount?.code, "image")} style={{ width: "100%", borderRadius: "6px" }} alt="" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div className={styles.boxAccountAgentText} style={{ fontWeight: "600" }}>
                                            {readIconBank(payAccount?.code, "name")}
                                            <br />
                                            ชื่อบัญชี  : {timeDepositProcess ? payAccount?.name : "-"}
                                            <br />
                                            เลขบัญชี : {timeDepositProcess ? payAccount?.account : "-"}
                                        </div>
                                    </Grid>
                                </Grid>

                                {
                                    timeDepositProcess && (
                                        <>
                                            <br />
                                            <div className="center">
                                                <button className={[buttonStyles.btnConfirm, buttonStyles.btnCopy].join(" ")} onClick={() => copyData(payAccount?.account)}>
                                                    คัดลอกเลขบัญชี
                                                </button>
                                            </div>

                                            {
                                                channelInfo?.event_select === "auto" && (
                                                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                                        <div className={styles.bankPromotionComment}>
                                                            ** กรุณาใช้บัญชี <span style={{ fontWeight: "bold" }}>"{depositItems?.customer_bank_account} - {depositItems?.customer_bank_account_name}"</span> โอนเงินมาเท่านั้น **
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </div>
                        </>


                        {
                            !timeDepositProcess && (
                                <>
                                    <br />
                                    <div className="center">
                                        <button className={[buttonStyles.btnRedirect, buttonStyles.btnConfirm].join(" ")} onClick={openContact}>
                                            ติดต่อแอดมิน
                                        </button>
                                    </div>

                                    <div className={[styles.boxMarginTop, "center"].join(" ")}>
                                        <div className={styles.bankPromotionComment}>
                                            ** หมดเวลาทำรายการ "กรุณาติดต่อแอดมิน เพื่อดำเนินการต่อ" **
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </>
            );
        }

        return null;
    };

    const renderContent = () => {
        if ((payMode === "iframe" || payMode === "link-tab") && paySrc) return renderContentIframe();

        if ((payMode === "account" || payMode === "qrcode") && (paySrc || payAccount)) {
            return renderContentNoIframe();
        };

        return null;
    };

    useEffect(() => {
        if (depositItems) {
            setPayMode(getStorage("payMode"));
            setPaySrc(getStorage("paySrc"));

            try {
                setPayAccount(JSON.parse(getStorage("payAccount")));
            } catch (error) {
                setPayAccount(null);
            }

            if (!timeDepositProcess) {
                setPaySrc("expired");
            }
        }
    }, [depositItems, timeDepositProcess]);

    useEffect(() => {
        if (homeData !== null) {
            setPromotionSwitch(homeData.promotion_auto === 1 ? true : false);
        }
    }, [homeData]);

    useEffect(() => {
        if (promotionSwitch !== null) {
            let promotion_auto = promotionSwitch ? 1 : 0;
            updatePromotionAuto(promotion_auto).then(() => { });
        }
    }, [promotionSwitch]);

    return (
        <>
            {
                renderContent()
            }

            <div className={styles.boxMarginTop}>
                <div className={styles.boxDetailTop}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <div className={styles.boxAccountAgentTitle}>
                                เลือกรับโปรโมชั่นอัตโนมัติ
                            </div>
                        </Grid>
                        <Grid item>
                            <Switch
                                isOn={promotionSwitch}
                                onColor="#38C172"
                                handleToggle={() => setPromotionSwitch(!promotionSwitch)}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    );
}